<!--  -->
<template>
  <div class="main_box">
    <div class="nav_menu">
      <div class="menu_left">
        <span style="color: #606266;padding-left: 15px">用户名称：</span>
        <el-input v-model="value" placeholder="请输入角色名称"></el-input>
        <el-button type="primary" @click="handleSearch">查询</el-button>
        <el-button type="info" @click="handleReset">重置</el-button>
      </div>
      <div class="menu_right">
        <el-button type="success" @click="addRole('add')">新增角色</el-button>
      </div>
    </div>
    <div>
      <el-table
        :header-cell-style="{
          'background-color': '#4073b6',
          color: '#FFFFFF'
        }"
        :data="tableData"
        stripe
        style="width: 100%"
        v-loading="loading"
        element-loading-text="数据量过大，加载中..."
      >
        <el-table-column type="index" prop="sid" label="序号" width="95">
        </el-table-column>
        <el-table-column prop="nickName" label="角色名称"> </el-table-column>
        <el-table-column
          prop="description"
          label="角色描述"
          show-overflow-tooltip
        >
        </el-table-column>

        <el-table-column width="250" label="操作">
          <template slot-scope="scope">
            <el-button class="edit" @click="addRole('edit', scope.row)"
              >编辑</el-button
            >
            <el-button class="delete" @click="handleDelete(scope.row)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        background
        layout="total, prev, pager, next, jumper"
        :total="pagination.total"
        @current-change="handleCurrent"
        :current-page="pagination.pageNum"
      >
      </el-pagination>
    </div>
    <!-- 新增用户 -->
    <el-dialog
      class="border-r-8 role-dialog"
      :visible.sync="addShow"
      width="calc(388/1920*100vw)"
      @open="dialogOpen"
      @close="closeBox"
      :destroy-on-close="true"
    >
      <div slot="title" class="dialogTitle t-x font-s-18">
        角色管理-{{ title }}
      </div>
      <div class="contentAdd" style="margin-bottom: 10px">
        <el-form :model="roleInfo" :rules="rules" ref="ruleForm">
          <el-form-item
            prop="nickName"
            style="height:calc(60/1080*100vh);margin-top:calc(25/1080*100vh)"
          >
            <span class="label_r">角色名称</span>
            <el-input
              v-model="roleInfo.nickName"
              placeholder="请输入角色名称"
              maxlength="10"
            ></el-input>
          </el-form-item>
          <el-form-item prop="description" style="height:calc(140/1080*100vh)">
            <span class="label_r">角色描述</span>
            <el-input
              type="textarea"
              resize="none"
              placeholder="请输入角色描述"
              maxlength="200"
              v-model="roleInfo.description"
            ></el-input>
          </el-form-item>
          <el-form-item style="margin-top: -20px">
            <span class="label_r">控制权限</span>
            <div class="menu_box">
              <el-tree
                ref="tree"
                :data="menuTree"
                node-key="id"
                :default-expanded-keys="[]"
                :default-checked-keys="roleList"
                :props="defaultProps"
                show-checkbox
              >
              </el-tree>
            </div>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleShow('ruleForm')" class="guanBi"
          >关闭</el-button
        >
        <el-button
          type="primary"
          style="margin-left:calc(30/1920*100vw)"
          @click="handleSave"
          >保存</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { menuList } from "../../../api/NavBar";
import { roleEdit, roleAdd } from "../../../api/role";
export default {
  components: {},
  data() {
    return {
      tableData: [],
      value: "",
      // 分页
      pagination: {
        size: 10,
        pageNum: 1,
        total: 0
      },
      title: "",
      roleInfo: {
        nickName: "", // 角色名
        description: "", // 描述
        menuId: [] //菜单list
      },
      addShow: false,
      menuList: [], // 菜单列表
      rules: {
        nickName: [
          { required: true, message: "请输入角色名", trigger: "change" }
        ],
        description: [
          { required: true, message: "请输入角色描述", trigger: "change" }
        ]
      },
      loading: true,
      props: {
        label: "name",
        children: "zones"
      },
      menuTree: [],
      defaultProps: {
        children: "children",
        label: "displayName"
      },
      roleList: [],
      timer: ""
    };
  },
  computed: {},
  watch: {},
  methods: {
    handleDelete(item) {
      let { id } = item;
      this.$confirm("此操作将永久删除该角色名称，确定删除？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          this.$post(`/role/deleteById/${id}`)
            .then(res => {
              this.$message({
                message: res.message,
                type: "success"
              });
              this.pagination.size = 10;
              if (this.pagination.total % 10 == 1) {
                this.pagination.pageNum--;
              }
            })
            .catch(err => {
              this.$message({
                message: err.message,
                type: "error"
              });
            })
            .finally(() => {
              this.getList();
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除"
          });
        });
    },
    // 列表
    getList() {
      this.loading = true;
      let { pageNum, size } = this.pagination;
      this.$get(`/role/page/${pageNum}/${size}`).then(res => {
        this.tableData = res.data.list;
        this.pagination.total = res.data.total;
        this.pagination.pageNum = res.data.pageNum;
        this.loading = false;
      });
    },
    // 获取菜单列表
    getMenuList() {
      menuList().then(res => {
        if (res.code == 200) {
          console.log("menuList", res.data);
          this.menuTree = res.data;
          return;
        }
      });
    },
    // 弹框显隐
    handleShow() {
      clearInterval(this.timer);
      this.addShow = false;
      this.timer = setTimeout(() => {
        this.$refs["ruleForm"].resetFields();
      }, 300);
    },
    // 保存
    handleSave() {
      let arr = [];
      this.$refs.tree.getCheckedNodes().forEach(item => {
        console.log(item);
        if (item.parentId != 0) {
          arr.push(item.id, item.parentId);
        } else {
          arr.push(item.id);
        }
      });
      this.menuList = [...new Set(arr)];
      this.$refs["ruleForm"].validate(valid => {
        if (valid) {
          if (this.title == "新增") {
            this.roleInfo.menuId = this.menuList;
            delete this.roleInfo.id;
            roleAdd(this.roleInfo)
              .then(res => {
                this.$message({
                  message: res.message,
                  type: "success"
                });
                this.getList();
                this.addShow = false;
                this.$refs["ruleForm"].resetFields();
              })
              .catch(err => {
                this.$message.error(err.message);
              })
              .finally(() => {});
            return;
          }
          // 修改
          roleEdit({ ...this.roleInfo, menuId: this.menuList })
            .then(res => {
              if (res.code == 200) {
                // this.$refs["ruleForm"].resetFields();
                this.$message({
                  message: res.message,
                  type: "success"
                });
                this.getList();
                this.addShow = false;
              }
            })
            .catch(err => {
              this.$message.error(err.message);
            })
            .finally(() => {});
        } else {
          return;
        }
      });
    },
    addRole(handle, row) {
      if (handle == "add") {
        this.title = "新增";
        this.roleList = [];

        // this.roleInfo = {
        // }
        if (this.menuTree.length) {
          this.addShow = true;
          clearTimeout(this.timer);
          this.timer = setTimeout(() => {
            this.$refs["ruleForm"].resetFields();
            this.$nextTick(() => {
              this.$refs.tree.setCheckedKeys(this.roleList); //获取已经设置的资源后渲染
            });
          }, 100);
          return;
        }
      } else {
        this.title = "编辑";
        this.addShow = true;
        this.roleInfo = JSON.parse(JSON.stringify(row));
        this.timer = setTimeout(() => {
          this.$nextTick(() => {
            this.$refs.tree.setCheckedKeys(this.roleList); //获取已经设置的资源后渲染
          });
        }, 100);
        this.roleInfo.nickName = row.nickName;
        this.roleInfo.description = row.description;
        this.roleInfo.id = row.id;
        this.roleList = row.menuId;
        this.$nextTick(() => {
          this.$refs.tree.setCheckedKeys(this.roleList); //获取已经设置的资源后渲染
        });
      }
    },
    // 分页
    handleCurrent(page) {
      this.pagination.pageNum = page;
      this.pagination.size = 10;
      this.getList();
    },
    // 查询
    handleSearch() {
      this.$get("./role/page/1/10", {
        nickName: this.value
      }).then(res => {
        this.tableData = res.data.list;
        this.pagination.total = res.data.total;
          this.pagination.pageNum = res.data.pageNum;
      });
    },
    // 重置
    handleReset() {
      this.value = "";
      this.getList();
    },
    dialogOpen() {
      this.$nextTick(() => {
        this.$refs.tree.setCheckedKeys([]); //获取已经设置的资源后渲染
      });
      this.$refs["ruleForm"].resetFields();
    },
    closeBox() {
      this.roleInfo.nickName = "";
      this.roleInfo.description = "";
      this.roleList = [];
    }
  },
  created() {
    this.getList();
    this.getMenuList();
  },
  mounted() {}
};
</script>
<style lang='scss' scoped>
.role-dialog {
  ::v-deep .el-dialog {
    background: #4073b6;
    border-radius: 16px;
    width: calc(388 / 1920 * 100vw) !important;
    .el-dialog__header {
      background: #fff;
      margin-top: 4px;
      border-radius: 16px 16px 0 0;
    }
    .el-dialog__footer {
      display: flex;
      justify-content: center;
      align-items: center;
      background: #fff;
      border-radius: 0 0 16px 16px;
      padding: calc(15 / 1080 * 100vh) 0;
      .el-button:nth-child(1):hover {
        background: #eaeaea;
        border: 1px #eaeaea solid;
        color: #313131;
      }
    }
    // 弹框主体
    .el-dialog__body {
      background: #fff;
      height: calc(395 / 1080 * 100vh);
      // 菜单选框
      .el-checkbox-group {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        label {
          width: 34% !important;
          height: calc(25 / 1080 * 100vh);
          margin-right: 0;
        }
      }
    }
    .el-input__inner {
      border: none;
      border-radius: 4px;
      background: #f3f4f8;
    }
  }
  .el-input,
  .el-textarea {
    width: calc(244 / 1920 * 100vw);
  }
  ::v-deep .el-textarea {
    height: calc(117 / 1080 * 100vh) !important;
    .el-textarea__inner {
      height: 100%;
    }
  }

  ::v-deep .el-form-item__content {
    display: flex;
    justify-content: center;
    margin: 0 auto;
    align-items: flex-start;
  }
  .label_r {
    margin-right: 20px !important;
    width: calc(75 / 1920 * 100vw);
    text-align: right;
    color: #666 !important;
  }
  .menu_box {
    width: calc(244 / 1920 * 100vw);
  }
}

::v-deep .el-form .el-form-item:nth-child(3) {
  .el-form-item__content {
    padding-top: 20px;
  }
}

// 请输入的样式
::v-deep .el-textarea__inner::placeholder {
  color: #999 !important;
  font-weight: bold !important;
}

::v-deep .el-form-item__error {
  left: calc(126 / 1920 * 100vw) !important;
}
.contentAdd {
  border-top: none;
}
::v-deep .el-dialog__header {
  border-bottom: 1px solid transparent;
  border-color: rgba(102, 102, 102, 0.1);
}
</style>
